.App {
  background-color: #fafafa;
  color: #333333;
  text-align: center;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  /* passed to App-header */
  pointer-events: none;
  user-select: none;

  transition:
    color 2s,
    background-color 1s;
}

.App:hover {
  background-color: #222222;
  color: #fafafa;
}

.App-header {
  width: 100vw;
  margin-top: 16vh;
  font-size: calc(10px + 2vmin);

  display: flex;
  flex-direction: column;
  align-items: center;

  pointer-events: auto;
}

.click {
  cursor: pointer;
}

.icon:focus {
  outline: 0;
}

.icon {
  background-color: transparent;
  border: none;
  display: block;
  text-align: center;

  position: relative;
  width: 270px;
  height: 7rem;

  transition:
    color .4s,
    border-radius .2s,
    background-color 2s,
    width 1s
    transform .4s;

  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.icon i {
  font-size: calc(4rem + 2vmin);
}

.icon:hover {
  color: #eaeaea;
  /* width: 20vw; */
}

.icon span {
  white-space: nowrap;
  position: absolute;
  left: 100%;
  opacity: 0;
  color: #222222;
  padding: 5px;
}

.icon:hover span {
  left: 1%;
  opacity: 1;
  color: #333333;
  background-color: #fafafa;
}

.icon span, .icon i, .dots span {
  transition:
    left .2s,
    opacity .4s,
    color 1s,
    background-color .4s,
    text-shadow 1.5s;
}

.glow {
  text-shadow: 0 0 10px #ffffc555;
}

.glow:hover i {
  text-shadow: 0 0 15px #fdfd96;
}

.glow-box {
  box-shadow: 0 0 10px #ffffc555;
}

.glow-box:hover i {
  box-shadow: 0 0 15px #fdfd96;
}

.dots {
  margin-top: 38px;
  cursor: default;
  overflow-wrap: anywhere;
}

.dots span {
  color: #fafafa;
  background-color: #222222;
  padding: 0 5px;
}

.dots span:hover {
  color: #222222;
  background-color: #fafafa;
}